.wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  /* grid-template-rows: 30px; */
  /* grid-auto-rows: minmax(10px, 30px); */
  row-gap: 1rem;
  vertical-align: middle;
}

.wrapper>div {
  padding: .5rem;
  display: flex;
  align-items: center;
}

.wrapper>div:nth-child(8n+1) {
  padding-left: 1rem;
}

.submitBtn {
  text-align: center;
}

.rowSubmitBtn {
  background: rgba(0, 30, 96, 1);
  background: -moz-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 30, 96, 1)), color-stop(100%, rgba(41, 65, 119, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e60', endColorstr='#294177', GradientType=0);
  color: #ffffff;
  font-weight: 400;
  padding: .5em 2em .5em 2em;
  border: none;
  border-radius: 10px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 16px system-ui;
  text-decoration: none;
}

.rowSubmitBtnDisabled {
  background: rgba(102, 98, 102, 1);
  background: -moz-linear-gradient(-45deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(102, 98, 102, 1)), color-stop(19%, rgba(125, 123, 125, 1)), color-stop(23%, rgba(127, 126, 127, 1)), color-stop(41%, rgba(138, 138, 138, 1)), color-stop(66%, rgba(153, 153, 153, 1)), color-stop(75%, rgba(158, 158, 158, 1)), color-stop(78%, rgba(166, 166, 166, 1)), color-stop(100%, rgba(226, 226, 226, 1)));
  background: -webkit-linear-gradient(-45deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  background: -o-linear-gradient(-45deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  background: linear-gradient(135deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#666266', endColorstr='#e2e2e2', GradientType=1);
  color: #ffffff;
  font-weight: 400;
  padding: .5em 2em .5em 2em;
  border: none;
  border-radius: 10px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 11px system-ui;
  text-decoration: none;
}

.gridHeader {
  background: rgba(0, 30, 96, 1);
  background: -moz-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 30, 96, 1)), color-stop(100%, rgba(41, 65, 119, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e60', endColorstr='#294177', GradientType=0);
  align-items: center;
  color: #ffffff;
  display: flex;
}

.subscription {
  padding: 0 2em 0 2em;
  align-items: center;
  text-align: center;
}

.headerSubscription {
  display: flex;
  justify-content: center;
}

.flexContainer {
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, .2);
}

.tableDirection {
  background: rgba(0, 30, 96, 1);
  background: -moz-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 30, 96, 1)), color-stop(100%, rgba(41, 65, 119, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e60', endColorstr='#294177', GradientType=0);
  color: #ffffff;
  height: 2.5em;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  padding: 0 0 0 1.25em;
  font-weight: 500;
}

.blankSpace {
  color: #ffffff;
  height: 2em;
}
