.select-css {
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .5rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e5e5e5));
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.select-css option {
    font-weight: normal;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  /* grid-template-rows: 30px; */
  /* grid-auto-rows: minmax(10px, 30px); */
  grid-row-gap: 1rem;
  row-gap: 1rem;
  vertical-align: middle;
}

.wrapper>div {
  padding: .5rem;
  display: flex;
  align-items: center;
}

.wrapper>div:nth-child(8n+1) {
  padding-left: 1rem;
}

.submitBtn {
  text-align: center;
}

.rowSubmitBtn {
  background: rgba(0, 30, 96, 1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 30, 96, 1)), color-stop(100%, rgba(41, 65, 119, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 30, 96, 1)), to(rgba(41, 65, 119, 1)));
  background: linear-gradient(to bottom, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e60', endColorstr='#294177', GradientType=0);
  color: #ffffff;
  font-weight: 400;
  padding: .5em 2em .5em 2em;
  border: none;
  border-radius: 10px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 16px system-ui;
  text-decoration: none;
}

.rowSubmitBtnDisabled {
  background: rgba(102, 98, 102, 1);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(102, 98, 102, 1)), color-stop(19%, rgba(125, 123, 125, 1)), color-stop(23%, rgba(127, 126, 127, 1)), color-stop(41%, rgba(138, 138, 138, 1)), color-stop(66%, rgba(153, 153, 153, 1)), color-stop(75%, rgba(158, 158, 158, 1)), color-stop(78%, rgba(166, 166, 166, 1)), color-stop(100%, rgba(226, 226, 226, 1)));
  background: -webkit-linear-gradient(-45deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  background: -webkit-linear-gradient(315deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  background: linear-gradient(135deg, rgba(102, 98, 102, 1) 0%, rgba(125, 123, 125, 1) 19%, rgba(127, 126, 127, 1) 23%, rgba(138, 138, 138, 1) 41%, rgba(153, 153, 153, 1) 66%, rgba(158, 158, 158, 1) 75%, rgba(166, 166, 166, 1) 78%, rgba(226, 226, 226, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#666266', endColorstr='#e2e2e2', GradientType=1);
  color: #ffffff;
  font-weight: 400;
  padding: .5em 2em .5em 2em;
  border: none;
  border-radius: 10px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 11px system-ui;
  text-decoration: none;
}

.gridHeader {
  background: rgba(0, 30, 96, 1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 30, 96, 1)), color-stop(100%, rgba(41, 65, 119, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 30, 96, 1)), to(rgba(41, 65, 119, 1)));
  background: linear-gradient(to bottom, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e60', endColorstr='#294177', GradientType=0);
  align-items: center;
  color: #ffffff;
  display: flex;
}

.subscription {
  padding: 0 2em 0 2em;
  align-items: center;
  text-align: center;
}

.headerSubscription {
  display: flex;
  justify-content: center;
}

.flexContainer {
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, .2);
}

.tableDirection {
  background: rgba(0, 30, 96, 1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 30, 96, 1)), color-stop(100%, rgba(41, 65, 119, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 30, 96, 1)), to(rgba(41, 65, 119, 1)));
  background: linear-gradient(to bottom, rgba(0, 30, 96, 1) 0%, rgba(41, 65, 119, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e60', endColorstr='#294177', GradientType=0);
  color: #ffffff;
  height: 2.5em;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  padding: 0 0 0 1.25em;
  font-weight: 500;
}

.blankSpace {
  color: #ffffff;
  height: 2em;
}

.groupGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-row-gap: 1rem;
  row-gap: 1rem;
  vertical-align: middle;
}

.memberGridWrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-row-gap: 1rem;
  row-gap: 1rem;
  vertical-align: middle;
}

.memberGridWrapper>div {
  padding: .5rem;
}

.memberGridWrapper>div:nth-child(8n+1) {
  padding-left: 1rem;
}

.submitBtn,
.groups {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.seats {
  padding-left: .5em;
}
body {
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p {
  color: #212121;
  font-size: 1em;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

h2 {
  font-size: 1.5em;
  margin: .75em 0
}

h3 {
  font-size: 1.17em;
  margin: .83em 0
}

code {
  font-family: 'Roboto', sans-serif;
}

.logo {
  max-width: 200px;
}

.header {
  background-color: #fff;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: #212121;
  margin: 30px 30px 0 30px;
  max-height: 50px;
}

.nav ul li {
  text-decoration: none;
  display: inline;
  padding: 15px;
  font-size: 1.17em;
}

.nav ul {
  margin: 5px 35px 0 0;
  text-transform: uppercase;
  text-decoration: none;
}

.nav ul a {
  text-decoration: none;
  color: #212121;
  background: none;
}

.nav li.active {
  border-bottom: #001E60 2px solid;
}

.nav li :hover {
  color: rgba(0, 0, 0, 0.5);
}

.main {
  display: flex;
  justify-content: center;
  min-width: 500px;
  min-height: 200px;
  margin: 3em 0 3em 0;
  flex: 1 0 auto;
}

footer {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#45484d+0,000000+100;Black+3D+%231 */
  background: rgb(69, 72, 77);
  /* Old browsers */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(69, 72, 77, 1) 0%, rgba(0, 0, 0, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(69, 72, 77, 1) 0%, rgba(0, 0, 0, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#45484d', endColorstr='#000000', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: 300px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  padding-left: 4em;
}

footer ul {
  padding: 4em 0;
}

footer ul li {
  color: #fff;
  text-decoration: none;
  list-style: none;
  display: inline;
  padding: 15px;
  font-size: 1.17em;
  flex-shrink: 0;
}

.copywrite {
  color: white;
  position: absolute;
  bottom: 2em;
  right: 4em;
}

