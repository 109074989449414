.groupGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 1rem;
  vertical-align: middle;
}

.memberGridWrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 1rem;
  vertical-align: middle;
}

.memberGridWrapper>div {
  padding: .5rem;
}

.memberGridWrapper>div:nth-child(8n+1) {
  padding-left: 1rem;
}

.submitBtn,
.groups {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.seats {
  padding-left: .5em;
}