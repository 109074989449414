body {
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p {
  color: #212121;
  font-size: 1em;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

h2 {
  font-size: 1.5em;
  margin: .75em 0
}

h3 {
  font-size: 1.17em;
  margin: .83em 0
}

code {
  font-family: 'Roboto', sans-serif;
}

.logo {
  max-width: 200px;
}

.header {
  background-color: #fff;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: #212121;
  margin: 30px 30px 0 30px;
  max-height: 50px;
}

.nav ul li {
  text-decoration: none;
  display: inline;
  padding: 15px;
  font-size: 1.17em;
}

.nav ul {
  margin: 5px 35px 0 0;
  text-transform: uppercase;
  text-decoration: none;
}

.nav ul a {
  text-decoration: none;
  color: #212121;
  background: none;
}

.nav li.active {
  border-bottom: #001E60 2px solid;
}

.nav li :hover {
  color: rgba(0, 0, 0, 0.5);
}

.main {
  display: flex;
  justify-content: center;
  min-width: 500px;
  min-height: 200px;
  margin: 3em 0 3em 0;
  flex: 1 0 auto;
}

footer {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#45484d+0,000000+100;Black+3D+%231 */
  background: rgb(69, 72, 77);
  /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(69, 72, 77, 1) 0%, rgba(0, 0, 0, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(69, 72, 77, 1) 0%, rgba(0, 0, 0, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(69, 72, 77, 1) 0%, rgba(0, 0, 0, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#45484d', endColorstr='#000000', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: 300px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  padding-left: 4em;
}

footer ul {
  padding: 4em 0;
}

footer ul li {
  color: #fff;
  text-decoration: none;
  list-style: none;
  display: inline;
  padding: 15px;
  font-size: 1.17em;
  flex-shrink: 0;
}

.copywrite {
  color: white;
  position: absolute;
  bottom: 2em;
  right: 4em;
}
